import SuccessFilled20 from '@pinvest/sirius-icons/dist/SuccessFilled20';
import Image from 'next/image';

import Text from '@/components/shared/Text';

import { ACDB2BRequirementIllustrationImageUrl, ACDB2BRequirementList } from './config';
import style from './styles.module.css';
import type { ACDB2BRequirementItem } from './types';

const ACDB2BRequirement = () => {
  return (
    <div className={style['acd-b2b__requirement']}>
      <div className={style['acd-b2b__requirement_left']}>
        <div className={style['acd-b2b__requirement-title']}>
          <Text typography='display2'>Syarat Pendaftaran & Pengajuan</Text>
        </div>
        <div className={style['acd-b2b__requirement-subtitle']}>
          <Text typography='body1' >Siapkan dokumen berikut ini untuk proses pendaftaran dan pengajuan yang lebih cepat.</Text>
        </div>
        <div className={style['acd-b2b__requirements']}>
          {
            ACDB2BRequirementList.map((req: ACDB2BRequirementItem, index: number) => {
              return (
                <div className={style['acd-b2b__requirement-wrapper']} key={index}>
                  <div className={style['acd-b2b__requirement-content-title']}>
                    <Text typography='heading1'>
                      {req.name}
                    </Text>
                  </div>
                  <div className={style['acd-b2b__requirement-content-subtitle']}>
                    <Text typography='body2' color='subtle'>{req.description}</Text>
                  </div>
                  <div className={style['acd-b2b__requirement-content-items']}>
                    {
                      req.termList.map((termItem: string, termIndex: number) => {
                        return (
                          <div className={style['acd-b2b__requirement-content-item']} key={termIndex}>
                            <SuccessFilled20 color='primary' />
                            <Text typography='body2'>{termItem}</Text>
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>
      <div className={style['acd-b2b__requirement_right']}>
        <div className={style['acd-b2b__requirement-image']}>
          <Image src={ACDB2BRequirementIllustrationImageUrl}
            height='100%' width='100%'
            layout='responsive' alt='acd-b2b-requirement-image' />
        </div>
      </div>
    </div>
  );

};

export default ACDB2BRequirement;