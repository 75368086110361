/* eslint-disable max-len */
const LinkedIn = () => {
  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.14732 13.9997H0.232251V4.65281H3.14732V13.9997ZM1.68821 3.37781C0.756071 3.37781 0 2.60906 0 1.68094C6.67188e-09 1.23512 0.177865 0.807572 0.494466 0.492335C0.811068 0.177098 1.24047 0 1.68821 0C2.13596 0 2.56536 0.177098 2.88196 0.492335C3.19856 0.807572 3.37643 1.23512 3.37643 1.68094C3.37643 2.60906 2.62004 3.37781 1.68821 3.37781ZM14.0575 13.9997H11.1487V9.44969C11.1487 8.36531 11.1267 6.97469 9.63308 6.97469C8.11749 6.97469 7.88524 8.15281 7.88524 9.37156V13.9997H4.97331V4.65281H7.76911V5.92781H7.80991C8.19909 5.19344 9.14975 4.41844 10.5681 4.41844C13.5183 4.41844 14.0606 6.35281 14.0606 8.86531V13.9997H14.0575Z" fill="currentColor" />
    </svg>
  );
};

export default LinkedIn;
