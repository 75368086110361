import Image from 'next/image';

import Text from '@/components/shared/Text';

import { ACDB2BFactList } from './config';
import styles from './styles.module.css';
import type { ACDB2BFact } from './types';

const ACDB2BFacts = () => {
  return (
    <div className={styles['acd-b2b__facts']}>
      {
        ACDB2BFactList.map((fact: ACDB2BFact, index: number) => {
          return (
            <div key={index} className={styles['acd-b2b__fact-card']}>
              <div className={styles['acd-b2b__fc_left']}>
                <div className={styles['acd-b2b__fc-image']}>
                  <Image src={fact.imageURL}
                    height='100%'
                    width='100%'
                    alt={`acd-b2b-fact-card-image-${index}`}
                    layout='responsive'
                  />
                </div>
              </div>
              <div className={styles['acd-b2b__fc_right']}>
                <div className={styles['acd-b2b__fc-title']}>
                  <Text typography='heading1'>{fact.title}</Text>
                </div>
                <div className={styles['acd-b2b__fc-description']} >
                  <Text typography='body1' color='disabled'>{fact.description}</Text>
                </div>
              </div>
            </div>
          );
        })
      }
    </div>
  );
};

export default ACDB2BFacts;