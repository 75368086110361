import { thousandSeparatorPositionRegex } from '@/utils/regex/commonRegex';

const formatCurrency = (val: number, noPrefix = false): string => {
  if (!val) return noPrefix ? '0' : 'Rp0';
  const result = val.toString().replace(thousandSeparatorPositionRegex, '.');

  if (noPrefix) {
    return result;
  }

  return `Rp${result}`;
};

export default formatCurrency;
