import internalUrls from '@/constants/internalUrls';

import type { ACDB2BApply } from './types';

export const ACDB2BApplyContent: ACDB2BApply = {
  image: 'https://pinhome-web-assets.imgix.net/pinfinance/apply-illustration-2.png',
  title: 'Tertarik untuk Mencoba Komisi Instan Pinhome?',
  description: 'Ajukan Komisi Instan Pinhome sekarang dan dapatkan berbagai keuntungannya!',
  url: internalUrls.blSubmission
};
