import type { ACDB2BFAQType } from './types';

export const acdB2bFaq: ACDB2BFAQType[] = [
  {
    question: 'Apa itu Pinhome Financial?',
    answer: `Pinhome Financial adalah salah satu produk dari Pinhome,
      platform e-commerce jual beli sewa properti, serta layanan rumah tangga pertama dan tepercaya di Indonesia.
      Layanan Pinhome Financial hadir untuk memberikan dukungan finansial kepada para pelaku usaha properti.`
  },
  {
    question: 'Siapa saja yang bisa mengajukan Komisi Instan Pinhome?',
    answer: `Seluruh Rekan Agen dari kantor atau developer properti
      yang telah bekerja sama dengan Pinhome dan terdaftar di aplikasi Rekan Agen Pinhome.`
  },
  {
    question: 'Apakah Komisi Instan Pinhome aman?',
    answer: `Ya, tentu aman! Pinhome bekerja sama dengan Penyelenggara Layanan Jasa Keuangan yaitu PT Indo Fin Tek (“Penyedia Layanan”)
      untuk menyediakan layanan Komisi Instan Pinhome. Penyelenggara telah terdaftar dan diawasi oleh Otoritas Jasa Keuangan (OJK)
      sebagai Fintech Lending/Peer to Peer Lending/Pinjaman Online dengan Surat Tanda Berizin No. KEP-47/D.05/2019.`
  },
  {
    question: 'Berapa limit komisi yang bisa saya ajukan?',
    answer: `Anda bisa mengajukan Komisi Instan hingga limit 2 miliar rupiah sesuai dengan syarat dan ketentuan yang berlaku.`
  },
  {
    question: 'Apakah program ini gratis?',
    answer: `Pinhome melalui Penyedia Layanan PT Indo Fin Tek (“Penyedia Layanan”) mengenakan biaya layanan dan biaya
    administrasi dari total pencairan yang diterima untuk setiap transaksi yang didaftarkan dalam program Komisi Instan Pinhome.`
  },
  {
    question: 'Bagaimana saya tahu progres pengajuan saya?',
    answer: `Untuk lihat progres pengajuan, Tekan tombol “Daftar/Masuk” pada
    website ini lalu isi nomor telepon untuk masuk ke akun Anda. Setelah masuk, klik tombol “Pengajuan Saya” yang ada pada menu`
  }
];

