
import ButtonPrimary from '@pinvest/sirius-button/dist/button_primary';
import Image from 'next/image';

import Check from '@/components/shared/Icon/Check';
import Text from '@/components/shared/Text';
import { useFeatureFlag } from '@/contexts/featureFlag';
import { useOAuth } from '@/contexts/oauth';

import { ACDB2BProductContent } from './config';
import style from './styles.module.css';
import type { ACDB2BProductProps } from './types';

const ACDB2BProduct = ({ handleOnApply }: ACDB2BProductProps) => {
  const { user } = useOAuth();
  const { cta_ajukan_bl_register } = useFeatureFlag();

  return (
    <div className={style['acd-b2b__product']}>
      <div className={style['acd-b2b__product_left']}>
        <div className={style['acd-b2b__product-image']}>
          <Image src={ACDB2BProductContent.imageUrl}
            height='100%' width='100%'
            layout='responsive' alt='acd-b2b-product-image' />
        </div>
      </div>
      <div className={style['acd-b2b__product_right']}>
        <Text typography='display2' component='p' margin='0 0 16px'>
          Layanan Pencairan Komisi Penjualan Agen dalam 7 Hari
        </Text>
        <div className={style['acd-b2b__product_copy']}>
          <Text typography='body1' >
            Komisi Instan adalah layanan dari Pinhome Financial untuk mempermudah
            Rekan Agen mendapatkan komisi penjualan lebih cepat.
          </Text>
        </div>
        <div className={style['acd-b2b__product-benefits']}>
          {
            ACDB2BProductContent.point.map((item: string) => {
              return (
                <div key={item} className={style['acd-b2b__product-benefit']}>
                  <Check />
                  <Text>{item}</Text>
                </div>
              );
            })
          }
        </div>
        <div className={style['acd-b2b__product_copy']}>
          <Text typography='body3' color='subtle'>
            *S&K Berlaku
          </Text>
        </div>
        <div className={style['acd-b2b__product-cta']}>
          <ButtonPrimary
            onClick={handleOnApply}
            size='large'
            target={cta_ajukan_bl_register && user ? '_blank' : undefined}
            testId='lnk-ACDB2B-ProductSection-apply'
          >
            Ajukan Sekarang
          </ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export default ACDB2BProduct;
