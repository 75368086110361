import { useRef, useCallback, useMemo } from 'react';

import { IconButton } from '@pinvest/sirius-button';
import { ArrowLeft24Icon, ArrowRight24Icon } from '@pinvest/sirius-icons';
import Image from 'next/image';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import type { Swiper as SwiperClass } from 'swiper/types/index';

import Text from '@/components/shared/Text';
import { useParsedUserAgent } from '@/contexts/parsedUserAgent';

import { testimonialList, trustParters } from './config';
import styles from './styles.module.css';

const ACDB2BTestimonial = () => {
  const { isMobile } = useParsedUserAgent();

  const navigationPrevRef = useRef<HTMLDivElement | null>(null);
  const navigationNextRef = useRef<HTMLDivElement | null>(null);

  const handleSwiperInit = useCallback((swiper: SwiperClass) => {
    if (typeof swiper.params.navigation === 'boolean' || !swiper.params.navigation) {
      return;
    }

    swiper.params.navigation.prevEl = navigationPrevRef.current;
    swiper.params.navigation.nextEl = navigationNextRef.current;
    swiper.navigation.init();
    swiper.navigation.update();
  }, []);

  const buttonNavigation = useMemo(() => {
    if (!isMobile) {
      return (
        <>
          <div className={styles['testimonial__cs-navigation_left']} ref={navigationPrevRef} >
            <IconButton appearance="outline" size="standard" icon={<ArrowLeft24Icon />} />
          </div>
          <div className={styles['testimonial__cs-navigation_right']} ref={navigationNextRef} >
            <IconButton appearance="outline" size="standard" icon={<ArrowRight24Icon />} />
          </div>
        </>
      );
    }

    return null;
  }, [isMobile]);

  return (
    <>
      <section className={styles.testimonial}>
        <div className={styles.testimonial__container}>
          <Text typography='display2' component='h1'>Apa kata mereka?</Text>
          <Text margin='1rem 0' typography='body1' component='p'>
            Pendapat mereka yang telah menggunakan layanan Pinhome Financial
          </Text>
          <div>
            <Swiper
              centerInsufficientSlides
              loop
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current
              }}
              onInit={handleSwiperInit}
              modules={[Navigation, Pagination]}
              slidesPerView={1.2}
              spaceBetween={24}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                  spaceBetween: 0
                }
              }}
              preventClicks
              preventClicksPropagation
            >
              {
                testimonialList.map((data, index) => {
                  return (
                    <SwiperSlide key={index} className={styles.slider}>
                      <div className={styles.slide}>
                        <div className={styles.slide__image}>
                          <Image src={data.image} alt='image' layout='responsive' width={200} height={200} />
                        </div>
                        <div>
                          <div className={styles.slide__title}>
                            <Text typography='display3' component='h1' textAlign='left'>{data.name}</Text>
                            <Text
                              typography='body1'
                              color='subtle'
                              textAlign='left'
                              component='h1'
                            >{data.companyAndUseProduct}
                            </Text>
                          </div>
                          <Text typography='body1' textAlign='left' component='p'>
                            <q>
                              {data.testimonial}
                            </q>
                          </Text>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })
              }
              {buttonNavigation}
            </Swiper>
          </div>
        </div>
      </section >
      <section className={styles['trusted-partners']}>
        <div className={styles['trusted-partners__container']}>
          <div className={styles['trusted-partners__title']}>
            <Text typography='display2'>Telah Dipercaya oleh</Text>
          </div>
          <div className={styles['trusted-partners__subtitle']}>
            <Text typography='body1'>Layanan Komisi Instan Pinhome telah dipercaya lebih dari 65+ kantor atau developer properti.</Text>
          </div>
          <div className={styles['trusted-partners__logos']}>
            {
              trustParters.map((partner, index: number) => {
                return (
                  <div className={styles['trusted-partners__logo']} key={index}>
                    <Image src={partner.url} layout='responsive' height={'100%'} width={'100%'} alt={partner.name} />
                  </div>
                );
              })
            }

          </div>
        </div>
      </section >
    </>
  );
};

export default ACDB2BTestimonial;
