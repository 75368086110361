import type { FooterLink } from './types';

export const aboutSection: FooterLink[] = [
  {
    label: 'Tentang Komisi Instan',
    href: '#acdb2b-product-section',
    name: 'product'
  },
  {
    label: 'Keuntungan',
    href: '#acdb2b-benefit-section',
    name: 'benefit'
  },
  {
    label: 'Syarat Pendaftaran dan Pengajuan',
    href: '#acdb2b-requirement-section',
    name: 'requirement'
  },
  {
    label: 'Langkah Pengajuan',
    href: '#acdb2b-steps-section',
    name: 'step'
  },
  {
    label: 'Simulasi',
    href: '#acdb2b-simulation-section',
    name: 'simulasi'
  },
  {
    label: 'FAQ',
    href: '#acdb2b-faq-section',
    name: 'faq'
  }
];

export const informationSection: FooterLink[] = [
  {
    label: 'Ketentuan & Kebijakan Privasi',
    href: '/kebijakan-privasi',
    name: 'ketentuan-kebijakan-privasi'
  }
];

export const PinfinanceWhiteLogo = 'https://pinhome-staging-public.s3.amazonaws.com/pinfinance-white-logo.png';
