import Image from 'next/image';

import Text from '@/components/shared/Text';

import { ACDB2BStepList } from './config';
import styles from './styles.module.css';
import type { ACDB2BStepType } from './types';

const ACDB2BStep = () => {
  return (
    <div className={styles['acd-b2b__steps-wrapper']}>
      <div className={styles['acd-b2b__steps-title']}>
        <Text typography='display2'>
          Langkah Mudah Pengajuan
        </Text>
      </div>
      <div className={styles['acd-b2b__steps-description']}>
        <Text typography='body1'>
          Proses pengajuan Komisi Instan Pinhome hanya membutuhkan waktu 2 menit.
        </Text>
      </div>
      <div className={styles['acd-b2b__steps']}>
        {
          ACDB2BStepList.map((step: ACDB2BStepType, index: number) => {
            return (
              <div key={index} className={styles['acd-b2b__step-card-wrapper']}>
                <div className={styles['acd-b2b__step-card-number']}>
                  <Text typography='heading3' color='white'>
                    {index + 1}
                  </Text>
                </div>
                <div className={styles['acd-b2b__step-card']}>
                  <div className={styles['acd-b2b__sc-image']}>
                    <Image src={step.image}
                      alt={`acd-b2b-sc-image-${index}`} height='70%'
                      width='100%' objectFit='contain'
                      layout='responsive' />
                  </div>
                  <div className={styles['acd-b2b__sc-title']}>
                    <Text typography='heading1'>
                      {step.title}
                    </Text>
                  </div>
                  <div className={styles['acd-b2b__sc-description']}>
                    <Text typography='body1' color='disabled'>
                      {step.description}
                    </Text>
                  </div>
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

export default ACDB2BStep;