import ApplySection from '@/components/shared/LandingPage/ApplySection';

import { ACDB2BApplyContent } from './config';
import styles from './styles.module.css';
import type { ACDB2BApplyProps } from './types';

const ACDB2BApply = ({ handleOnApply }: ACDB2BApplyProps) => {

  return (
    <div className={styles['acd-b2b__apply']}>
      <ApplySection
        image={ACDB2BApplyContent.image} title={ACDB2BApplyContent.title}
        description={ACDB2BApplyContent.description} onClickAjukanCTA={handleOnApply} />
    </div >
  );
};

export default ACDB2BApply;
