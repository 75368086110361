import React, { useCallback } from 'react';

import { ButtonOutline, ButtonPrimary, IconButton } from '@pinvest/sirius-button';
import { ArrowLeft24Icon, ArrowRight24Icon } from '@pinvest/sirius-icons';
import Image from 'next/image';
import { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import type { Swiper as SwiperClass } from 'swiper/types/index';

import Text from '@/components/shared/Text';
import { useParsedUserAgent } from '@/contexts/parsedUserAgent';


import styles from './styles.module.css';
import type { CarouselBannerType } from './types';
import type { CarouselBannerProps, CarouselPartner } from './types';
import { renderCustom } from './utils';


const CarouselBanner = ({ carouselBannerList, handleSimulate, autoplay = false }: CarouselBannerProps) => {
  const { isMobile } = useParsedUserAgent();

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  const handleSwiperInit = useCallback((swiper: SwiperClass) => {
    if (typeof swiper.params.navigation === 'boolean' || !swiper.params.navigation) {
      return;
    }

    swiper.params.navigation.prevEl = navigationPrevRef.current;
    swiper.params.navigation.nextEl = navigationNextRef.current;

    swiper.navigation.init();
    swiper.navigation.update();
  }, []);


  const applyButton = useCallback((banner: CarouselBannerType) => {
    if (typeof banner.applyNowCTA === 'function') {
      return (
        <div className={styles['cs-cta']}>
          <ButtonPrimary
            target='_blank'
            size='large'
            onClick={banner.applyNowCTA}
            testId='btn-banner-apply'
          >
            Ajukan Sekarang
          </ButtonPrimary>
        </div>
      );

    }

    if (typeof banner.applyNowCTA == 'string' && typeof banner.applyNowCTAMobile == 'string' && banner.applyNowCTA && banner.applyNowCTAMobile) {
      return <div className={styles['cs-cta']}>
        <ButtonPrimary
          href={isMobile ? banner.applyNowCTAMobile : banner.applyNowCTA}
          target='_blank'
          size='large'
        >
          Ajukan Sekarang
        </ButtonPrimary>
      </div>;
    }
    return <></>;
  }, [isMobile]);

  return (
    <div className={styles['carousel-banner']}>
      <Swiper
        loop={true}
        pagination={{
          clickable: true,
          type: 'custom',
          renderCustom: renderCustom
        }}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current
        }}
        onInit={handleSwiperInit}
        modules={[Navigation, Pagination, Autoplay]}
        slidesPerView={1}
        preventClicks={false}
        preventClicksPropagation={true}
        allowTouchMove={false}
        autoplay={autoplay ? {
          delay: 3000
        } : false}
      >
        {
          carouselBannerList.map((banner: CarouselBannerType, index: number) => {
            return (
              <SwiperSlide key={index} >
                <div className={styles['carousel-slide']} >
                  <div className={styles['cs_left']}>
                    <Text typography='label1' color='primary' component='p' margin='0 0 24px'>
                      {banner.title}
                    </Text>
                    <Text typography='display1' color='default' component='p' margin='0 0 24px'>
                      {banner.tagline}
                    </Text>
                    {(banner.description && typeof banner.description == 'string') &&
                      (
                        <Text typography='body1' color='default' component='p' margin='0 0 24px'>
                          {banner.description}
                        </Text>
                      )
                    }
                    {(banner.description && typeof banner.description != 'string') &&
                      (
                        banner.description
                      )
                    }
                    <div className={styles['cs-partners']}>
                      <Text typography='body1' color='disabled' component='p' margin='0 0 13px'>
                        Bekerja sama dan diawasi oleh:
                      </Text>
                      <div className={styles['cs-partner-list']}>
                        {
                          banner.partners.map((partner: CarouselPartner, indexPartner: number) => {
                            return (
                              <span key={indexPartner} className={styles['cs-partner-image']}>
                                <Image src={partner.url} alt={partner.name} height="40" width="100" objectFit='contain' />
                              </span>
                            );
                          })
                        }
                      </div>
                    </div>
                    <div className={styles['cs-ctas']}>
                      {
                        applyButton(banner)
                      }
                      {(banner.simulationCTA && handleSimulate) &&
                        <div className={styles['cs-cta']}>
                          <ButtonOutline onClick={handleSimulate} testId='btn-banner-simulation' size='large'>
                            Coba Simulasi
                          </ButtonOutline>
                        </div>
                      }
                      {banner.seePromoCTA &&
                        <div className={styles['cs-cta']}>
                          <ButtonOutline href={banner.seePromoCTA}>
                            Lihat Promo
                          </ButtonOutline>
                        </div>
                      }
                    </div>
                  </div>
                  <div className={styles['cs_right']}>
                    <Image
                      src={banner.bannerImage}
                      layout='responsive'
                      width='100%' height='100%'
                      objectFit='contain' alt={`carousel-banner-image-${index}`} />
                  </div>
                </div>
              </SwiperSlide>
            );
          })
        }
        {carouselBannerList.length > 1 &&
          <>
            <div className={styles['cs-navigation_left']} ref={navigationNextRef} >
              <IconButton appearance="outline" size="standard" icon={<ArrowLeft24Icon />} />
            </div>
            <div className={styles['cs-navigation_right']} ref={navigationPrevRef} >
              <IconButton appearance="outline" size="standard" icon={<ArrowRight24Icon />} />
            </div>
          </>
        }
      </Swiper >
    </div >
  );
};

export default CarouselBanner;
