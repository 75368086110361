import { useContext } from 'react';

import type { FeatureFlagConfig } from '@/types/general/featureFlagConfig';

import { FeatureFlagContext } from './context';

/**
 * A Custom React hook to query for `featureFlags`.
 * 
 * The return type of this hook is object with keys are feature flag keys and values are boolean.
 * 
 * @example
 * const {
 *  feature_flag_a: flagA,
 *  feature_flag_b: flagB
 * } = useFeatureFlag();
 */
export function useFeatureFlag(): FeatureFlagConfig {
  const flagConfig = useContext(FeatureFlagContext);

  if (typeof flagConfig === 'undefined') {
    throw new Error('`useFeatureFlag` was used outside of `FeatureFlagProvider`');
  }

  return flagConfig;
}
