import Accordion from '@/components/shared/Accordion';
import Text from '@/components/shared/Text';

import { acdB2bFaq } from './config';
import styles from './styles.module.css';
import type { ACDB2BFAQType } from './types';

const ACDB2BFAQ = () => {
  return (
    <div className={styles['acd-b2b__faq']} id='acdb2b-faq-section'>
      <Text component='h1' typography='display2'>Pertanyaan Terkait Komisi Instan</Text>
      <div className={styles['acd-b2b__faq-questions']}>
        {
          acdB2bFaq.map((qa: ACDB2BFAQType, index) => {
            return <Accordion key={index} title={qa.question} body={qa.answer} />;
          })
        }
      </div>
    </div>
  );
};

export default ACDB2BFAQ;
