import type { ACDB2BStepType } from './types';

export const ACDB2BStepList: ACDB2BStepType[] = [
  {
    title: 'Pendaftaran & Pengajuan',
    description: 'Daftarkan akun kantor Anda dan isi seluruh persyaratan pengajuan yang dibutuhkan.',
    image: 'https://pinhome-web-assets.imgix.net/pinfinance/bridging-loan/apply-step-1.png'
  },
  {
    title: 'Verifikasi Data',
    description: 'Tunggu hasil verfifikasi dari tim Pinhome Financial dan Partner maksimal 7 hari* kerja.',
    image: 'https://pinhome-web-assets.imgix.net/pinfinance/bridging-loan/apply-step-2.png'
  },
  {
    title: 'Pencairan Komisi',
    description: 'Setelah disetujui, Komisi instan akan dikirim ke akun bank yang Anda daftarkan.',
    image: 'https://pinhome-web-assets.imgix.net/pinfinance/bridging-loan/apply-step-3.png'
  },
  {
    title: 'Pembayaran Tagihan',
    description: 'Bayar kembali tagihan Komisi Instan Anda sesuai waktu yang ditentukan.',
    image: 'https://pinhome-web-assets.imgix.net/pinfinance/bridging-loan/apply-step-4.png'
  }
];