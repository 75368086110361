import type { ACDB2BBenefitType } from './types';

export const ACDB2BBenefitList: ACDB2BBenefitType[] = [
  {
    title: 'Meningkatkan Performa Rekan Agen',
    description: 'Buat agen lebih bersemangat jual beli properti dengan pencairan komisi lebih cepat.',
    image: 'https://pinhome-web-assets.imgix.net/pinfinance/agent-peformance.png'
  },
  {
    title: 'Membangun Loyalitas Rekan Agen',
    description: 'Berikan dukungan lebih pada kesejahteraan agen untuk membangun loyalitas agen.',
    image: 'https://pinhome-web-assets.imgix.net/pinfinance/agent-loyalty.png'
  },
  {
    title: 'Meningkatkan Reputasi Baik Kantor',
    description: 'Tingkatkan nilai tambah kantor Anda guna menarik minat para agen baru untuk bergabung.',
    image: 'https://pinhome-web-assets.imgix.net/pinfinance/office-reputation.png'
  },
  {
    title: 'Membantu Menjaga Cashflow Kantor',
    description: 'Jaga arus keuangan kantor dengan memanfaatkan layanan Komisi Instan Pinhome.',
    image: 'https://pinhome-web-assets.imgix.net/pinfinance/cashflow.png'
  }
];