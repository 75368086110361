import type { ACDB2BRequirementItem } from './types';

export const ACDB2BRequirementIllustrationImageUrl = 'https://pinhome-web-assets.imgix.net/pinfinance/document-with-bubble.png';

export const ACDB2BRequirementList: ACDB2BRequirementItem[] = [
  {
    name: 'Syarat Pendaftaran',
    description: 'Anda hanya perlu melakukan pendaftaran 1x dan tidak perlu daftar ulang untuk pengajuan Komisi Instan berikutnya.',
    termList: [
      'Foto KTP & NPWP ',
      'Data PIC & Usaha',
      'Rekening Bank',
      'Dokumen Legalitas Usaha'
    ]
  },
  {
    name: 'Syarat Pengajuan',
    description: 'Dokumen di bawah ini diperlukan untuk setiap pengajuan Komisi Instan. Pastikan agar dokumen terlihat jelas.',
    termList: [
      'Dokumen Surat Pemesanan Rumah',
      'Kuitansi Pembayaran Terakhir dari Pembeli',
      'Surat Pernyataan Pembayaran Komisi'
    ]
  }
];