import clsx from 'clsx';
import type Swiper from 'swiper';

import styles from './styles.module.css';

export const renderCustom = (_swiper: Swiper, current: number, total:number) => {
  let text = '<span>';
  for (let i = 1; i <= total; i++) {
    text += `<span class=\"swiper-pagination-bullet 
              ${clsx(styles['swiper-pagination-bullet'], current == i && styles['swiper-pagination-bullet-active'])}
              \">
              </span>`;
  }
  text += '</span>';
  return text;
};