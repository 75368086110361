import internalUrls from '@/constants/internalUrls';

export const ACDB2BProductContent = {
  imageUrl: 'https://pinhome-web-assets.imgix.net/pinfinance/komisi-instan/description-illustration.png',
  title: 'Layanan Pencairan Komisi Penjualan Agen dalam 7 Hari',
  subtitle: 'Komisi Instan adalah layanan dari Pinhome Financial untuk mempermudah Rekan Agen mendapatkan komisi penjualan lebih cepat.',
  point: [
    'Pencairan komisi hingga 30% dalam 7 hari*',
    'Limit pengajuan sampai dengan 2 miliar*',
    'Tenor pengembalian mulai dari 3 hingga 6 bulan',
    'Biaya layanan yang rendah dimulai dari 1.99% per bulan',
    'Pengajuan tanpa agunan'
  ],
  ctaAjukanLink: internalUrls.blSubmission
};