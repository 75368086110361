import type { ReactNode } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import ChevronDown20Icon from '@pinvest/sirius-icons/dist/ChevronDown20';

import styles from './styles.module.css';

const Accordion = ({
  title,
  body,
  initialOpen = false,
  dataTestIdName,
  footer
}: { title: string | ReactNode, body: string | ReactNode, initialOpen?: boolean, dataTestIdName?: string, footer?: ReactNode }) => {
  const [expanded, setExpanded] = useState<boolean>(initialOpen);
  const [finalHeight, setFinalHeight] = useState<number>(1);
  const panelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let currentHeight = 1;
    if (expanded) {
      currentHeight = panelRef.current?.scrollHeight || 1;
    }

    setFinalHeight(currentHeight);
  }, [expanded]);


  const handleAccordionClick = useCallback(() => {
    setExpanded(prevState => !prevState);
  }, []);

  return (
    <div
      className={styles.accordion__item}
      aria-expanded={expanded}
      data-testid={`accordion-${dataTestIdName}`}
    >
      <button
        className={styles['accordion__item-heading']}
        onClick={handleAccordionClick}
        data-testid={`btn-${dataTestIdName}`}
      >
        {typeof title === 'string' ?
          <h3 className={styles['accordion__item-title']}>{title}</h3> :
          <div className={styles['accordion__item-title']}>{title}</div>
        }
        <ChevronDown20Icon />
      </button>
      <div
        className={styles['accordion__item-body']}
        role='region'
        ref={panelRef}
        style={{ height: finalHeight }}
      >
        {body}
      </div>
      {footer &&
        footer
      }
    </div>
  );
};

export default Accordion;
