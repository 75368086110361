import cx from 'clsx';

import styles from './styles.module.css';
import type { SpinnerProps } from './types';

const Spinner = ({
  color,
  size
}: SpinnerProps) => {
  return (
    <div
      className={cx(styles.spinner, [styles[`spinner_${color}`]], [styles[`spinner_${size}`]])}
    />
  );
};

export default Spinner;
