export enum ProfileCompletionPageName {
  PROFILE_COMPLETION_FORM = 'profile_completion_form',
  DASHBOARD = 'dashboard',
  PROFILE_EDIT_FORM = 'profile_edit_form'
}

export enum ProfileCompletionOpenPageAttribute {
  SIGN_UP_FORM = 'sign_up_form',
  DASHBOARD_MENU = 'dashboard_menu',
  DASHBOARD_MAIN = 'dashboard_main',
  DASHBOARD_RIGHT_BAR = 'dashboard_right_bar',
  NAV_BAR_ACD_APPLY = 'nav_bar_acd_apply',
  HERO_BANNER_ACD_APPLY = 'hero_banner_acd_apply',
  INFO_BANNER_ACD = 'info_banner_acd',
  SIMULATION_ACD_APPLY = 'simulation_acd_apply',
  DIRECT_LINK = 'direct_link',
  BOTTOM_BANNER_ACD_APPLY = 'bottom_banner_acd_apply',
  PROMO_BANNER_ACD_B2B_APPLY = 'promo_banner_acd_b2b_apply'
}

export enum ProfileCompletionClickButtonAttribute {
  DASHBOARD_MAIN = 'dashboard_main',
  DASHBOARD_RIGHT_BAR = 'dashboard_right_bar',
  NAV_BAR_ACD_APPLY = 'nav_bar_acd_apply',
  HERO_BANNER_ACD_APPLY = 'hero_banner_acd_apply',
  INFO_BANNER_ACD = 'info_banner_acd',
  SIMULATION_ACD_APPLY = 'simulation_acd_apply',
  SIGN_UP_FORM = 'sign_up_form',
  DASHBOARD_MENU = 'dashboard_menu',
  PROFILE_MENU = 'profile_menu',
  DASHBOARD_PROFILE_MENU = 'dashboard_profile_menu',
  DIRECT_LINK = 'direct_link',
  BOTTOM_BANNER_ACD_APPLY = 'bottom_banner_acd_apply'
}

export enum ProfileCompletionGetResponseAttribute {
  SIGN_UP_FORM = 'sign_up_form',
  DASHBOARD_MENU = 'dashboard_menu',
  DASHBOARD_MAIN = 'dashboard_main',
  DASHBOARD_RIGHT_BAR = 'dashboard_right_bar',
  NAV_BAR_ACD_APPLY = 'nav_bar_acd_apply',
  HERO_BANNER_ACD_APPLY = 'hero_banner_acd_apply',
  INFO_BANNER_ACD = 'info_banner_acd',
  SIMULATION_ACD_APPLY = 'simulation_acd_apply',
  PROFILE_MENU = 'profile_menu',
  DIRECT_LINK = 'direct_link',
  DASHBOARD_PROFILE_MENU = 'dashboard_profile_menu'
}

export enum ProfileCompletionButtonName {
  COMPLETE_PROFILE = 'complete_profile',
  RETURN = 'return',
  COMPLETE_LATER = 'complete_later',
  TO_DASHBOARD = 'to_dashboard,',
  APPLY_ACD = 'apply_acd',
  DASHBOARD_PROFILE_MENU = 'dashboard_profile_menu',
  SAVE_PROFILE = 'save_profile',
  UPLOAD_IMAGE = 'upload_image'
}

export enum ProfileCompletionStatusName {
  SUCCESS = 'success',
  FAILED_NOT_COMPLETE = 'failed_not_complete',
  FAILED_SERVER_ERROR = 'failed_server_error',
  FAILED_MAX_FILE_SIZE_REACHED = 'failed_max_file_size_reached'
}

export enum ProfileCompletionPartName {
  APPLICANT_INFO = 'applicant_info',
  BUSINESS_ENTITY_INFO = 'business_entity_info',
  DOCUMENT = 'document'
}

export type ProfileCompletionOpenPageParam = {
  page_name: ProfileCompletionPageName;
  attribute: ProfileCompletionOpenPageAttribute;
};

export type ProfileCompletionClickButtonParam = {
  page_name: ProfileCompletionPageName;
  button_name: ProfileCompletionButtonName;
  attribute: ProfileCompletionClickButtonAttribute;
  profile_completion_status?: ProfileCompletionStatusName;
  profile_completion_part?: ProfileCompletionPartName;
};

export type ProfileCompletionGetResponseParam = {
  customer_guid: string;
  attribute: ProfileCompletionGetResponseAttribute;
  profile_completion_step?: ProfileCompletionPartName;
};