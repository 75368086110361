import { useCallback, useEffect, useMemo, useState } from 'react';

import { ButtonOutline, ButtonPrimary } from '@pinvest/sirius-button';
import { useRouter } from 'next/router';

import CarouselBanner from '@/components/shared/CarouselBanner';
import type { CarouselBannerType } from '@/components/shared/CarouselBanner/types';
import HelpSection from '@/components/shared/LandingPage/HelpSection';
import PartnerSection from '@/components/shared/LandingPage/PartnerSection';
import MetaHead from '@/components/shared/MetaHead';
import NavigationBar from '@/components/shared/NavigationBar';
import SubmissionPopUp from '@/components/shared/SubmissionPopUp';
import AppTemplate from '@/components/shared/templates/AppTemplate';
import Text from '@/components/shared/Text';
import { icpPartnerDisclaimer } from '@/constants/generalContent';
import internalUrls from '@/constants/internalUrls';
import { useFeatureFlag } from '@/contexts/featureFlag';
import { useOAuth } from '@/contexts/oauth';
import { useParsedUserAgent } from '@/contexts/parsedUserAgent';
import cookie from '@/services/cookies';
import EventTrackingService from '@/services/eventTracking';
import { pinfinanceDiscoveryClickButton, pinfinanceDiscoveryOpenPage } from '@/services/eventTracking/events/pinfinanceDiscovery';
import { PinfinanceDiscoveryButtonNameAttr } from '@/services/eventTracking/events/pinfinanceDiscovery/types';
import { PinfinanceDiscoveryAttr, PinfinanceDiscoveryButtonName, PinfinanceDiscoveryOpenPageName }
  from '@/services/eventTracking/events/pinfinanceDiscovery/types';
import { ProfileCompletionOpenPageAttribute } from '@/services/eventTracking/events/profileCompletion/types';
import { ProfileCompletionPageName } from '@/services/eventTracking/events/profileCompletion/types';

import ACDB2BApply from './components/ACDB2BApply';
import ACDB2BBenefit from './components/ACDB2BBenefit';
import ACDB2BFacts from './components/ACDB2BFact';
import ACDB2BFAQ from './components/ACDB2BFAQ';
import ACDB2BProduct from './components/ACDB2BProduct';
import ACDB2BRequirement from './components/ACDB2BRequirement';
import ACDB2BSimulation from './components/ACDB2BSimulation';
import ACDB2BStep from './components/ACDB2BStep';
import ACDB2BTestimonial from './components/ACDB2BTestimonials';
import FloatingBtnWA from './components/FloatingBtnWA';
import ACDB2BFooter from './components/Footer';
import {
  ACDB2BBannerList, acdB2BPartner, metaDescription, metaTitle
} from './config';
import styles from './styles.module.css';
import type { ACDB2BPageProps } from './types';

const ACDB2BPage = ({ isEligibleSubmission }: ACDB2BPageProps) => {
  const { cta_login_register_navbar } = useFeatureFlag();
  const { user } = useOAuth();
  const { isMobile } = useParsedUserAgent();
  const router = useRouter();

  const [isShowSubmissionPopUp, setIsShowSubmissionPopUp] = useState(false);

  const handleOnApply = useCallback((attribute?: ProfileCompletionOpenPageAttribute) => {
    if (!user) {
      router.push(internalUrls.login);
      return;
    }

    if (user && !isEligibleSubmission) {
      if (attribute) {
        cookie.add({
          name: ProfileCompletionPageName.PROFILE_COMPLETION_FORM,
          value: JSON.stringify({ attribute: attribute })
        });
      }

      router.push(internalUrls.completeProfile);
      return;
    }

    if (isEligibleSubmission) {
      setIsShowSubmissionPopUp(true);
      return;
    }

  }, [isEligibleSubmission, router, user]);

  const handleOnNavbarApplyClicked = useCallback(() => {
    if (isEligibleSubmission) {
      EventTrackingService.pushEvent(pinfinanceDiscoveryClickButton({
        page_name: PinfinanceDiscoveryOpenPageName.ACD_B2B_HOMEPAGE,
        attribute: PinfinanceDiscoveryButtonNameAttr.NAV_BAR_ACD_B2B_APPLY,
        button_name: PinfinanceDiscoveryButtonName.ACD_B2B_SUBMISSION
      }));
    }

    handleOnApply(ProfileCompletionOpenPageAttribute.NAV_BAR_ACD_APPLY);
  }, [handleOnApply, isEligibleSubmission]);

  const handleOnHeroBannerApplyClicked = useCallback(() => {
    if (isEligibleSubmission) {
      EventTrackingService.pushEvent(pinfinanceDiscoveryClickButton({
        page_name: PinfinanceDiscoveryOpenPageName.ACD_B2B_HOMEPAGE,
        attribute: PinfinanceDiscoveryButtonNameAttr.HERO_BANNER_ACD_B2B_APPLY,
        button_name: PinfinanceDiscoveryButtonName.ACD_B2B_SUBMISSION
      }));
    }

    handleOnApply(ProfileCompletionOpenPageAttribute.HERO_BANNER_ACD_APPLY);
  }, [handleOnApply, isEligibleSubmission]);

  const handleOnHeroPromoBannerApplyClicked = useCallback(() => {
    if (isEligibleSubmission) {
      EventTrackingService.pushEvent(pinfinanceDiscoveryClickButton({
        page_name: PinfinanceDiscoveryOpenPageName.ACD_B2B_HOMEPAGE,
        attribute: PinfinanceDiscoveryButtonNameAttr.PROMO_BANNER_ACD_B2B_APPLY,
        button_name: PinfinanceDiscoveryButtonName.ACD_B2B_SUBMISSION
      }));
    }

    handleOnApply(ProfileCompletionOpenPageAttribute.PROMO_BANNER_ACD_B2B_APPLY);
  }, [handleOnApply, isEligibleSubmission]);

  const handleOnLoanSectionApplyClicked = useCallback(() => {
    if (isEligibleSubmission) {
      EventTrackingService.pushEvent(pinfinanceDiscoveryClickButton({
        page_name: PinfinanceDiscoveryOpenPageName.ACD_B2B_HOMEPAGE,
        attribute: PinfinanceDiscoveryButtonNameAttr.ACD_B2B_LOAN_PRODUCT_SELECTION,
        button_name: PinfinanceDiscoveryButtonName.ACD_B2B_SUBMISSION
      }));
    }

    handleOnApply(ProfileCompletionOpenPageAttribute.INFO_BANNER_ACD);
  }, [handleOnApply, isEligibleSubmission]);

  const handleOnSimulationApplyClicked = useCallback(() => {
    if (isEligibleSubmission) {
      EventTrackingService.pushEvent(pinfinanceDiscoveryClickButton({
        page_name: PinfinanceDiscoveryOpenPageName.ACD_B2B_HOMEPAGE,
        attribute: PinfinanceDiscoveryButtonNameAttr.SIMULATION_ACD_B2B_APPLY,
        button_name: PinfinanceDiscoveryButtonName.ACD_B2B_SUBMISSION
      }));
    }

    handleOnApply(ProfileCompletionOpenPageAttribute.SIMULATION_ACD_APPLY);
  }, [handleOnApply, isEligibleSubmission]);

  const handleOnBottomBannerApplyClicked = useCallback(() => {
    if (isEligibleSubmission) {
      EventTrackingService.pushEvent(pinfinanceDiscoveryClickButton({
        page_name: PinfinanceDiscoveryOpenPageName.ACD_B2B_HOMEPAGE,
        attribute: PinfinanceDiscoveryButtonNameAttr.BOTTOM_BANNER_ACD_B2B_APPLY,
        button_name: PinfinanceDiscoveryButtonName.ACD_B2B_SUBMISSION
      }));
    }

    handleOnApply(ProfileCompletionOpenPageAttribute.BOTTOM_BANNER_ACD_APPLY);
  }, [handleOnApply, isEligibleSubmission]);

  const handleClickContactUsHelpBanner = useCallback(() => {
    EventTrackingService.pushEvent(pinfinanceDiscoveryClickButton({
      page_name: PinfinanceDiscoveryOpenPageName.ACD_B2B_HOMEPAGE,
      attribute: PinfinanceDiscoveryButtonNameAttr.HELP_BANNER,
      button_name: PinfinanceDiscoveryButtonName.CONTACT_US
    }));
  }, []);

  const handleClickContactUsFloating = useCallback(() => {
    EventTrackingService.pushEvent(pinfinanceDiscoveryClickButton({
      page_name: PinfinanceDiscoveryOpenPageName.ACD_B2B_HOMEPAGE,
      attribute: PinfinanceDiscoveryButtonNameAttr.WA_FLOATING,
      button_name: PinfinanceDiscoveryButtonName.CONTACT_US
    }));
  }, []);

  const closeModal = useCallback(() => {
    setIsShowSubmissionPopUp(false);
  }, []);

  const scrollIntoView = useCallback((id: string, offset: number = 0) => {
    const el = document.getElementById(id);

    if (!!el) {
      window.scrollTo({
        behavior: 'smooth',
        top:
          el.getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          offset
      });
    }
  }, []);

  const handleSimulate = useCallback(() => {
    scrollIntoView('acdb2b-simulation-section', 50);
  }, [scrollIntoView]);

  const applyACDButton = useMemo(() => {
    return (
      <ButtonPrimary
        onClick={handleOnNavbarApplyClicked}
        testId="btn-apply-navbar-acdB2b"
      >
        <div className={styles['acd-b2b__navbar-cta']}>
          Ajukan Komisi Instan
        </div>
      </ButtonPrimary>
    );
  }, [handleOnNavbarApplyClicked]);

  const regisLoginButton = useMemo(() => {
    return (
      <ButtonOutline
        testId="btn-masukDaftar-navbar"
        href={internalUrls.register}
      >
        Daftar / Masuk
      </ButtonOutline>
    );
  }, []);

  const extraNavigationItems = useMemo(() => {
    return (
      <div className={styles['acd-b2b__extra-navbar']}>
        {cta_login_register_navbar && !user &&
          <div className={styles['acd-b2b__extra-navbar-item']}>
            {regisLoginButton}
          </div>
        }
        {(!isMobile || (isMobile && !cta_login_register_navbar)) &&
          <div className={styles['acd-b2b__extra-navbar-item']}>
            {applyACDButton}
          </div>
        }
      </div >
    );
  }, [cta_login_register_navbar, user, regisLoginButton, isMobile, applyACDButton]);


  const extraMainNavigationItems = useMemo(() => {
    return (
      <div className={styles['acd-b2b__extra-navbar']}>
        <div className={styles['acd-b2b__extra-navbar-item_non-button']}>
          <a data-testid='lnk-navbar-item' onClick={() => scrollIntoView('acdb2b-product-section', 200)}>
            <Text typography='body2' color='default'>Tentang Komisi Instan</Text>
          </a>
        </div>
      </div >
    );
  }, [scrollIntoView]);

  const extraNavigationItemsOverlay = useMemo(() => {
    return (
      <div className={styles['acd-b2b__extra-navbar_overlay']}>
        {cta_login_register_navbar && !user &&
          <div className={styles['acd-b2b__extra-navbar_overlay-item']}>
            {regisLoginButton}
          </div>
        }
        {cta_login_register_navbar &&
          <div className={styles['acd-b2b__extra-navbar_overlay-item']}>
            {applyACDButton}
          </div>
        }
      </div>
    );
  }, [applyACDButton, cta_login_register_navbar, regisLoginButton, user]);

  const ACDB2BBanners = useMemo(() => {
    const ACDB2Banner = { ...ACDB2BBannerList[0], applyNowCTA: handleOnHeroBannerApplyClicked };
    const ACDB2PromoBanner = { ...ACDB2BBannerList[1], applyNowCTA: handleOnHeroPromoBannerApplyClicked };
    const modifiedACDB2BBanners: CarouselBannerType[] = [];
    modifiedACDB2BBanners.push(ACDB2Banner, ACDB2PromoBanner);
    return modifiedACDB2BBanners;
  }, [handleOnHeroBannerApplyClicked, handleOnHeroPromoBannerApplyClicked]);

  useEffect(() => {
    const res = cookie.get(PinfinanceDiscoveryOpenPageName.ACD_B2B_HOMEPAGE);

    cookie.delete(PinfinanceDiscoveryOpenPageName.ACD_B2B_HOMEPAGE);

    const parsedRes = res ? JSON.parse(res) : null;
    if (!parsedRes?.attribute) {
      EventTrackingService.pushEvent(pinfinanceDiscoveryOpenPage(
        {
          page_name: PinfinanceDiscoveryOpenPageName.ACD_B2B_HOMEPAGE,
          attribute: PinfinanceDiscoveryAttr.DIRECT_LINK
        }
      ));
      return;
    }

    EventTrackingService.pushEvent(pinfinanceDiscoveryOpenPage(
      {
        page_name: PinfinanceDiscoveryOpenPageName.ACD_B2B_HOMEPAGE,
        attribute: parsedRes.attribute
      }
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppTemplate showFooter={false}>
      <MetaHead title={metaTitle} description={metaDescription} />
      <NavigationBar
        isShowMenu={false}
        extraNavigationItems={extraNavigationItems}
        extraMainNavigationItems={extraMainNavigationItems}
        extraNavigationItemsOverlay={extraNavigationItemsOverlay}
      />
      <div className={styles['acd-b2b__page']}>
        <section className={styles['acd-b2b__carousel-section']}>
          <CarouselBanner autoplay carouselBannerList={ACDB2BBanners} handleSimulate={handleSimulate} />
        </section>
        <section className={styles['acd-b2b__fact-section']}>
          <ACDB2BFacts />
        </section>
        <section className={styles['acd-b2b__product-section']} id='acdb2b-product-section'>
          <ACDB2BProduct handleOnApply={handleOnLoanSectionApplyClicked} />
        </section>
        <section className={styles['acd-b2b__benefit-section']} id='acdb2b-benefit-section'>
          <ACDB2BBenefit />
        </section>
        <section className={styles['acd-b2b__requirement-section']} id='acdb2b-requirement-section'>
          <ACDB2BRequirement />
        </section>
        <section className={styles['acd-b2b__step-section']} id='acdb2b-steps-section'>
          <ACDB2BStep />
        </section>
        <section className={styles['acd-b2b__simulation-section']} id="acdb2b-simulation-section">
          <ACDB2BSimulation handleOnApply={handleOnSimulationApplyClicked} />
        </section>
        <section className={styles['acd-b2b__apply-section']}>
          <ACDB2BApply handleOnApply={handleOnBottomBannerApplyClicked} />
        </section>
        <ACDB2BTestimonial />
        <PartnerSection
          partnersImage={acdB2BPartner}
          partnerDescription={icpPartnerDisclaimer}
          backgroundColor='attentive'
        />
        <ACDB2BFAQ />
        <HelpSection
          onClickButtonContactUs={handleClickContactUsHelpBanner}
          title='Butuh Bantuan?'
          description='Hubungi kami atau kunjungi Pusat Bantuan untuk pertanyaan lebih lanjut tentang Komisi Instan.' />
      </div>
      <ACDB2BFooter />
      <SubmissionPopUp isShowSubmissionPopUp={isShowSubmissionPopUp} closeModal={closeModal} userEmail={user?.email ?? ''} />
      <FloatingBtnWA onButtonClicked={handleClickContactUsFloating} />
    </AppTemplate>
  );
};

export default ACDB2BPage;
