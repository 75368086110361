import { ButtonOutlinePrimary } from '@pinvest/sirius-button';
import Image from 'next/image';

import Text from '@/components/shared/Text';

import styles from './styles.module.css';
import type { ApplySectionProps } from './types';

const ApplySection = ({
  image,
  title,
  description,
  url,
  onClickAjukanCTA
}: ApplySectionProps) => {
  return (
    <div className={styles['apply-section__wrapper']}>
      <div className={styles['apply-section__image']}>
        <Image src={image} height='65' width='100' objectFit='contain' layout='responsive' alt='apply-section-image' />
      </div>
      <div className={styles['apply-section__copy']}>
        <div className={styles['apply-section__copy-title']}>
          <Text typography='display2' color='white'>
            {title}
          </Text>
        </div>
        <div className={styles['apply-section__copy-description']}>
          <Text typography='body1' color='white'>
            {description}
          </Text>
        </div>
        <div className={styles['apply-section__apply-cta']}>
          <ButtonOutlinePrimary
            size='large'
            href={url}
            target='_blank'
            testId='btn-banner'
            onClick={onClickAjukanCTA}
          >
            Ajukan Sekarang
          </ButtonOutlinePrimary>
        </div>
      </div >
    </div >
  );
};

export default ApplySection;
