
export const metaTitle = 'Komisi Instan: Pencairan Komisi Agen dalam 7 Hari*';

export const metaDescription =
  // eslint-disable-next-line max-len
  'Ajukan Komisi Instan Pinhome (KIP) dari Pinhome Financial untuk dapatkan layanan pencairan komisi penjualan properti hingga 30% dalam waktu 7 hari*.';

import type { CarouselBannerType } from '@/components/shared/CarouselBanner/types';
import Check from '@/components/shared/Icon/Check';
import Text from '@/components/shared/Text';
import { dompetKilatLogoUrl, ojkLogoUrl } from '@/constants/images';
import internalUrls from '@/constants/internalUrls';

import styles from './styles.module.css';

export const ACDB2BBannerList: CarouselBannerType[] = [
  {
    title: 'Komisi Instan Pinhome',
    tagline: 'Cairkan Komisi Lebih Cepat untuk Tingkatkan Performa Agen',
    description: `Ajukan Komisi Instan Pinhome (KIP) dari Pinhome Financial untuk dapatkan 
    layanan pencairan komisi penjualan properti hingga 30% dalam waktu 7 hari*.`,
    partners: [
      {
        name: 'dompet-kilat-logo',
        url: dompetKilatLogoUrl
      },
      {
        name: 'ojk-logo',
        url: ojkLogoUrl
      }
    ],
    applyNowCTA: internalUrls.blSubmission,
    applyNowCTAMobile: internalUrls.blSubmission,
    simulationCTA: true,
    seePromoCTA: null,
    bannerImage: 'https://pinhome-web-assets.imgix.net/pinfinance/acd-b2b/banner-main.png'
  },
  {
    title: 'Promo',
    tagline: 'Ajukan Lewat Website, Dapatkan Promo Hingga 50%',
    description: <div className={styles['banner-description']}>
      <Text typography='body1'>
        Jangan lewatkan promo spesial khusus untuk semua pengajuan Komisi Instan melalui website Pinhome Financial sebelum 30 Juni 2023.
      </Text>
      <div className={styles['banner-description__item']}>
        <Check />
        <Text margin={'8px 0 0 16px'}>Potongan <b>Biaya Admin</b> dari 4% menjadi <b>2% per transaksi</b></Text>
      </div>
      <div className={styles['banner-description__item']}>
        <Check />
        <Text margin={'8px 0 0 16px'}>Potongan <b>Bunga</b> dari 1.99% menjadi 1.59% <b>per bulan</b></Text>
      </div>
    </div>,
    partners: [
      {
        name: 'dompet-kilat-logo',
        url: dompetKilatLogoUrl
      },
      {
        name: 'ojk-logo',
        url: ojkLogoUrl
      }
    ],
    applyNowCTA: internalUrls.blSubmission,
    applyNowCTAMobile: internalUrls.blSubmission,
    simulationCTA: true,
    seePromoCTA: null,
    bannerImage: 'https://pinhome-web-assets.imgix.net/pinfinance/acd-b2b/banner-promo.png'
  }
];

export const acdB2BPartner = 'https://pinhome-web-assets.imgix.net/partner-pinfinance-dk.png';

export const acdB2BDeeplinkGooglePlay = 'https://play.google.com/store/apps/details?id=id.pinhome.agent&hl=en&gl=US';
export const acdB2BDeeplinkAppStore = 'https://apps.apple.com/id/app/rekan-agen-pinhome/id1502834833?l=id';
export const acdB2BDeeplinkMobile = 'https://rekan.pinhome.id/app/komisi-instan';

