export enum LOAN_PRODUCT_TYPE {
  ACD_B2B = 'loan_product_type.acd_b2b',
  ACD_B2C = 'loan_product_type.acd_b2c',
  ACD_B2C_BROKERAGE = 'loan_product_type.acd_b2c_brokerage',
  PINTAS_B2B = 'loan_product_type.pintas_b2b',
  PINTAS_COMMUNITY = 'loan_product_type.pintas_community',
  PINTAS_INDIVIDUAL = 'loan_product_type.pintas_individual',
  COMMUNITY_LOAN = 'loan_product_type.community_loan',
  BRIDGING_LOAN_CONSTRUCTION_BUSINESS = 'loan_product_type.bridging_loan_construction_business',
  BRIDGING_LOAN_CONSTRUCTION_INDIVIDUAL = 'loan_product_type.bridging_loan_construction_individual',
  BRIDGING_LOAN_NON_CONSTRUCTION_BUSINESS = 'loan_product_type.bridging_loan_non_construction_business',
  BRIDGING_LOAN_NON_CONSTRUCTION_INDIVIDUAL = 'loan_product_type.bridging_loan_non_construction_individual'
}

export enum LOAN_STATUS_NAME {
  CREATED = 'loan_status.created',
  APPROVED = 'loan_status.approved',
  REJECTED = 'loan_status.rejected',
  CANCELLED = 'loan_status.cancelled',
  DISBURSED = 'loan_status.disbursed',
  COLLECTION_PERIOD = 'loan_status.collection_period',
  COLLECTION_COMPLETE = 'loan_status.collection_complete',
  DATA_ENTRY = 'loan_status.data_entry',
  VERIFICATION = 'loan_status.verification',
  RISK_ASSESSMENT = 'loan_status.risk_assessment',
  APPROVAL_REQUEST = 'loan_status.approval_request',
  READY_TO_BE_DISBURSE = 'loan_status.ready_to_be_disburse'
};

export const LoanStatusFilter = [
  {
    localeKey: `${LOAN_STATUS_NAME.CREATED}`,
    localeName: 'Pengajuan Terkirim'
  },
  {
    localeKey:
      `${LOAN_STATUS_NAME.DATA_ENTRY},${LOAN_STATUS_NAME.VERIFICATION},${LOAN_STATUS_NAME.APPROVAL_REQUEST}`,
    localeName: 'Pengecekan Dokumen'
  },
  {
    localeKey: `${LOAN_STATUS_NAME.APPROVED}`,
    localeName: 'Pengajuan Disetujui'
  },
  {
    localeKey: `${LOAN_STATUS_NAME.READY_TO_BE_DISBURSE}`,
    localeName: 'TTD Berita Acara'
  },
  {
    localeKey: `${LOAN_STATUS_NAME.DISBURSED}`,
    localeName: 'Komisi Dicairkan'
  }
];

export enum REPAYMENT_METHOD {
  oneTime = 'repayment_method.one_time',
  installment = 'repayment_method.installment'
}

export const repaymentOptions = [
  { localeName: 'Satu Kali Pembayaran', localeKey: REPAYMENT_METHOD.oneTime },
  { localeName: 'Cicilan', localeKey: REPAYMENT_METHOD.installment }
];
