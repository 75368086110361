import Text from '@/components/shared/Text';

import ACDB2BCalculator from './components/ACDB2BSimulationCalculator';
import styles from './styles.module.css';
import type { ACDB2BSimulationProps } from './types';

const ACDB2BSimulation = ({ handleOnApply }: ACDB2BSimulationProps) => {
  return (
    <div className={styles['acd-b2b__simulation']}>
      <div className={styles['acd-b2b__simulation_left']}>
        <div className={styles['acd-b2b__simulation-title']}>
          <Text typography="display2">
            Simulasi Komisi Instan
          </Text>
        </div>
        <div className={styles['acd-b2b__simulation-description']}>
          <Text typography="body1">
            Simulasi ini <b>hanya estimasi</b>. Perhitungan sebenarnya akan disesuaikan dengan detail pengajuan dari masing-masing pemohon.
          </Text>
        </div>
      </div>
      <div className={styles['acd-b2b__simulation_right']}>
        <ACDB2BCalculator handleOnApply={handleOnApply} />
      </div>
    </div>
  );
};

export default ACDB2BSimulation;
