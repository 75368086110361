import WhatsApp32 from '@pinvest/sirius-icons/dist/WhatsApp32';

import { waPinfinance2 } from '@/constants/externalUrls';

import styles from './styles.module.css';
import type { FloatingBtnWAProps } from './types';

const FloatingBtnWA = ({ onButtonClicked }: FloatingBtnWAProps) => {
  return (
    <a className={styles.container} href={waPinfinance2}
      onClick={onButtonClicked} target='_blank'
      rel="noreferrer"
      data-testid='btn-floatingButton-contactUs'>
      <div className={styles['icon-container']}>
        <WhatsApp32 className={styles.icon} color='white' />
      </div>
    </a>
  );
};

export default FloatingBtnWA;
