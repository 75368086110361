import Image from 'next/image';

import Text from '@/components/shared/Text';

import { ACDB2BBenefitList } from './config';
import styles from './styles.module.css';
import type { ACDB2BBenefitType } from './types';

const ACDB2BBenefit = () => {
  return (
    <div className={styles['acd-b2b__benefits-wrapper']}>
      <div className={styles['acd-b2b__benefits-title']}>
        <Text typography='display2'>
          Keuntungan Komisi Instan Pinhome
        </Text>
      </div>
      <div className={styles['acd-b2b__benefits-description']}>
        <Text typography='body1'> Tingkatkan performa Rekan Agen dan reputasi kantor Anda dengan berbagai keuntungan Komisi Instan.</Text>
      </div>
      <div className={styles['acd-b2b__benefits']}>
        {
          ACDB2BBenefitList.map((benefit: ACDB2BBenefitType, index: number) => {
            return (
              <div key={index} className={styles['acd-b2b__benefit-card']}>
                <div className={styles['acd-b2b__bc-image']}>
                  <Image src={benefit.image}
                    alt={`acd-b2b-bc-image-${index}`} height='100%'
                    width='100%' layout='responsive' />
                </div>
                <div className={styles['acd-b2b__bc-title']}>
                  <Text typography='heading1'>
                    {benefit.title}
                  </Text>
                </div>
                <div className={styles['acd-b2b__bc-description']}>
                  <Text typography='body1' color='disabled'>
                    {benefit.description}
                  </Text>
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

export default ACDB2BBenefit;