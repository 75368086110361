import type { ACDB2BFact } from './types';

export const ACDB2BFactList: ACDB2BFact[] = [
  {
    title: ' 65+ Kantor dan Developer Properti',
    description: 'Telah memanfaatkan layanan Komisi Instan Pinhome.',
    imageURL: 'https://pinhome-web-assets.imgix.net/pinfinance/komisi-instan/pinhome-agent-couple.png'
  },
  {
    title: '95% Agen',
    description: 'Puas dengan layanan Komisi Instan Pinhome.',
    imageURL: 'https://pinhome-web-assets.imgix.net/pinfinance/komisi-instan/pinhome-agent-couple-satified.png'
  }
];